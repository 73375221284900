"use client"
import type React from "react"
import { useState, useEffect, useRef } from "react"
import PhoneInput from "react-phone-input-2"
import "react-phone-input-2/lib/style.css"
import { X } from "lucide-react"

interface UnifiedAuthInputProps {
  value: string
  onChange: (value: string, isEmail: boolean, countryCode?: string) => void
  placeholder?: string
  className?: string
  forcePhone?: boolean
}

export const UnifiedAuthInput: React.FC<UnifiedAuthInputProps> = ({
  value,
  onChange,
  placeholder = "Enter phone number",
  className = "",
  forcePhone = true, // Default to phone input mode
}) => {
  const [phoneNumber, setPhoneNumber] = useState("")
  const [countryCode, setCountryCode] = useState("")
  const [inputValue, setInputValue] = useState("")
  const phoneInputRef = useRef<any>(null)

  // Initialize or update from props
  useEffect(() => {
    if (value !== phoneNumber) {
      setPhoneNumber(value)
    }
  }, [value])

  const handlePhoneInputChange = (inputValue: string, data: any) => {
    setInputValue(inputValue)

    if (!inputValue) {
      setPhoneNumber("")
      setCountryCode("")
      onChange("", false, "")
      return
    }

    // Extract the country code properly
    const newCountryCode = `+${data.dialCode}`

    // Get the national number by removing the country code
    let nationalNumber = inputValue
    if (nationalNumber.startsWith(data.dialCode)) {
      nationalNumber = nationalNumber.substring(data.dialCode.length)
    }

    // Clean the number to contain only digits
    nationalNumber = nationalNumber.replace(/[^0-9]/g, "")

    setPhoneNumber(nationalNumber)
    setCountryCode(newCountryCode)

    // Pass clean number & country code to parent
    onChange(nationalNumber, false, newCountryCode)
  }

  // Handle country change separately to ensure proper country code updates
  const handleCountryChange = (value: string, data: any) => {
    const newCountryCode = `+${data.dialCode}`
    setCountryCode(newCountryCode)
    setInputValue(data.dialCode)

    // When country changes, keep the national number part
    onChange(phoneNumber, false, newCountryCode)
  }

  // Clear the entire input including country code
  const handleClearInput = () => {
    if (phoneInputRef.current) {
      // Reset all values
      setPhoneNumber("")
      setCountryCode("")
      setInputValue("")
      onChange("", false, "")

      // Force reset the PhoneInput component
      phoneInputRef.current.numberInputRef.value = ""
      phoneInputRef.current.numberInputRef.focus()
    }
  }

  return (
    <div className={`relative ${className}`}>
      <PhoneInput
        ref={phoneInputRef}
        country={"ae"}
        value={inputValue}
        onChange={handlePhoneInputChange}
        onCountryChange={handleCountryChange}
        enableSearch={true}
        countryCodeEditable={true}
        disableSearchIcon={false}
        searchPlaceholder="Search country..."
        inputProps={{
          name: "phone",
          required: true,
          placeholder: placeholder,
          className:
            "w-full pl-12 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm",
        }}
        inputStyle={{
          width: "100%",
          height: "38px",
          fontSize: "14px",
          borderRadius: "0.375rem",
          borderColor: "#D1D5DB",
          paddingRight: "2.5rem", // Make room for the clear button
        }}
        buttonStyle={{
          borderTopLeftRadius: "0.375rem",
          borderBottomLeftRadius: "0.375rem",
          borderColor: "#D1D5DB",
        }}
        dropdownStyle={{
          width: "300px",
          maxHeight: "300px",
        }}
        searchStyle={{
          width: "100%",
          padding: "8px",
          margin: "0 0 8px",
        }}
        containerStyle={{
          width: "100%",
        }}
      />

      {/* Clear button */}
      {(inputValue || phoneNumber || countryCode) && (
        <button
          type="button"
          onClick={handleClearInput}
          className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-400 hover:text-gray-700 transition-colors"
          aria-label="Clear input"
        >
          <X className="h-4 w-4" />
        </button>
      )}
    </div>
  )
}

